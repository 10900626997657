import React from "react"

import "./ServiceDocHeader.scss"

export default function ServiceDocHeader({ data }) {
  const { images, subtitle } = data
  return (
    <section
      className="servicedoc-container"
      style={{ backgroundImage: `url(${images.bgTop})` }}
    >
      <div className="servicedoc-wrapper">
        <div className="servicedoc-text">
          <div className="servicedoc-icon-container">
            <img
              src={images.logo}
              alt="Servicedoc application icon"
              className="servicedoc-logo"
            />
          </div>
          <h1 className="servicedoc-subtitle">{subtitle}</h1>
        </div>
      </div>
    </section>
  )
}
