import {
  Delivered,
  Header,
  Logo,
  MockUp,
} from "../../images/SingleCase/ServiceDoc"

const dataForServiceDoc = {
  type: "project",
  smart: true,
  classComponent: "Servicedoc",
  nextLink: "cases/city-portal/",
  prevLink: "cases/2cents/",
  imageSrc: [],
  data: {
    title: "Service Doc",
    subtitle:
      "ServiceDoc is a tool that allows creation and verification of legal documents. It allows creating and tailoring contracts, application forms, tax form and other document types without any legal knowledge in a matter of minutes.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Private company from Ukraine that provides a wide range of legal services.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The initial pain was automation of internal legal processes but when it became apparent that the pain is universal for the whole industry, the client decided to develop a separate product for wider market reach.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "Node.JS",
            "React.JS",
            "MongoDB",
            "XDocReport",
            "Apache PDFBox",
            "JSON",
            "HTML",
            "XML",
            "CSS",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Database Developer",
              count: 1,
            },
            {
              type: "DevOps",
              count: 1,
            },
            {
              type: "React Developers",
              count: 6,
            },
            {
              type: "QA Engineers",
              count: 2,
            },
            {
              type: "Designers",
              count: 3,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 24,
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The software allows creating legal documents by completing short surveys. It also offers intuitive editing tools and online advice from active industry professionals.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Access rights management",
        "Storing Regulation Offices data - courts, prosecutors etc.",
        "Creating and styling document templates",
        "Advanced editing tools",
        "Ease of use both for professional community and for people unfamiliar with legal issues",
        "Intuitive UI for communication with contractors",
        "Document translation and interpreting",
        "History of the documents created",
        "Interactive document filling with consult from professional lawyers",
        "Flexible penalty and processing time system for operators",
        "Flexible pricing: by category and by single document type",
        "Messaging system - chat (web-sockets)",
        "Notification system",
        "Courier delivery for completed documents",
        "Reports on:",
        [
          "documents filled by category",
          "individual operators - documents checked, time spent etc.",
          "cash flow",
        ],
      ],
    },
    images: {
      bgTop: Header,
      logo: Logo,
      mockUpStay: MockUp,
      delivered: Delivered,
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/service-doc",
}

export default dataForServiceDoc
