import React from "react"

import OverviewComponent from "../OverviewComponent"

const renderList = data => {
  let ind = 100
  return (
    <ul key={ind++}>
      {data.map((item, i) => {
        if (typeof item === "string") {
          return <li key={i}>{item}</li>
        } else if (Array.isArray(item)) {
          return renderList(item)
        }
        return null
      })}
    </ul>
  )
}

export default function Servicedoc({ data }) {
  let i = 0
  return [
    // <section key={i++}>
    //   <div className='bgBlock bgImage Servicedoc' style={{ backgroundImage: `url(${data.images.bgTop})` }}>
    //     <div className="container">
    //       <div className='row'>
    //         <div className='col-md-12' style={{ height: '100%' }}>
    //           <div className='titleBlock'>
    //             <div className='iconLogo'>
    //               <img src={data.images.logo} alt={'Servicedoc application icon'} />
    //             </div>
    //             <h1 className='Servicedoc-subheader'>{data.subtitle}</h1>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    <OverviewComponent
      {...data}
      classComponent="white-bg Servicedoc"
      key={i++}
    />,

    <section className="white-bg" key={i++}>
      <div className="image">
        <img
          src={data.images.mockUpStay}
          alt="Servicedoc application"
          className="fixed"
        />
      </div>
    </section>,

    <section className="white-bg solution first" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="solution last Servicedoc" key={i++}>
      <div className="ServicedocContainer">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h2 className="noMarginTop">{data.delivered.title}</h2>
              {renderList(data.delivered.list)}
            </div>
          </div>
        </div>
      </div>
    </section>,
  ]
}
